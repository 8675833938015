<template>
  <div class="page_bg">
    <!-- 顶部返回 -->
    <div class="back_top_name flex-r-sb-c" style=" padding: 0.32rem;background: #ffffff;font-size: 0.48rem;">
      <van-icon name="arrow-left" @click="backPath" />
      <div class="top_name">关于我们</div>
      <div class="top_icon" />
    </div>
    <div class="help-box" style="background: #ffffff;">
      <div class="help-body" style="line-height: 1.8;">
        <div class="margin-top" style="font-size: 0.5rem;font-weight: bold;">兽医首选</div>
        <div class="margin-top" style="font-size: 0.4rem;font-weight: bold;">品牌简介</div>
        <div style="text-indent:0.8rem">
          兽医首选™（南京瀚星信息科技有限公司旗下品牌）于2017年11月成立，深耕宠物医疗健康产业链，是一家科技驱动型的宠物医疗健康产品提供商。
        </div>
        <div style="text-indent:0.8rem">
          兽医首选™通过服务整合主流宠物医疗健康产品供应商资源网络，以线上B2B商城、线下前店后仓为触点，构建数字化采配、分销和仓储物流体系，通过多层次的数字化流通体系和供应链技术提升宠物医疗健康产品流通效率。
        </div>
        <div style="text-indent:0.8rem">
          此外，兽医首选™面向全国2.8万家宠物医院，以宠物医疗健康产品供应链为切入点，提供宠物专科医疗技能培训和宠物医院专业能力升级、信息化系统建设、联盟品牌建设，以及宠物主人养宠健康教育等服务，为宠物医院专业科室建设提供全方位的服务。
        </div>
        <br><br>
      </div>
    </div>
    <div id="player" />
  </div>
</template>

<script>
import './index.scss'
export default {
  data() {
    return {
      player: null,
      playerJs: 'https://player.polyv.net/resp/live-h5-player/latest/liveplayer.min.js',
      uid: 'dbc6f019be',
      vid: '3924345'
    }
  },
  mounted() {
    this.loadPlayerScript(this.loadPlayer)
  },
  created() {
  },
  methods: {
    backPath() { window.history.back() },
    loadPlayerScript(callback) {
      if (!window.polyvLivePlayer) {
        const myScript = document.createElement('script')
        myScript.setAttribute('src', this.playerJs)
        myScript.onload = callback
        document.body.appendChild(myScript)
        this.$nextTick(() => {
          console.log('静音')
          this.player.j2s_setVolume(0)
          setTimeout(() => {
            console.log('播放')
            this.player.j2s_resumeVideo()
          }, 1000)
        })
      } else {
        callback()
      }
    },
    loadPlayer() {
      const polyvLivePlayer = window.polyvLivePlayer
      this.player = polyvLivePlayer({
        wrap: '#player',
        width: 300,
        height: 533,
        uid: this.uid,
        vid: this.vid,
        isAutoChange: true,
        autoplay: true,
        coverImg: 'https://oss.xiaoyi120.com/shop2.0/login/login_logo.png'
      })
    },
    destroyed() {
      if (this.player) {
        this.player.destroy()
      }
    }
  }
}
</script>

<style lang="scss">
.help-box {
  line-height: 1.6;
  padding: 0 0.4rem;
  .help-title {
    font-size: 0.45rem;
    line-height: 1rem;
    font-weight: bold;
    border-bottom: 1px #eee solid;
    text-align: center;
  }
  .hel-body {
    margin-top: 0.2rem;
  }
}
</style>
